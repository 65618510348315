import { FC, useState } from "react"

import { Button } from "../atoms/Button/Button"
import { BUTTON_VARIANT } from "../atoms/Button/constants"
import { TextArea } from "../atoms/TextArea/TextArea"
import AlertMessage, { MessageType } from "../modules/AlertMessage/AlertMessage"
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../modules/Modal/Modal"

import "./RejectDocumentModal.scss"

export interface RejectDocumentModalProps {
  setShowRejectModal: (value: boolean) => void
  label?: string
  buttonLabel?: string
  headerLabel?: string
  handleReject: ({
    rejectInput,
    setHasRejectError,
  }: {
    rejectInput: string
    setHasRejectError: (hasRejectError: boolean) => void
  }) => Promise<void>
  rejectError?: JSX.Element | null
}

const DefaultRejectError = (
  <AlertMessage
    className="reject-document-modal-error"
    messageType={MessageType.ERROR}
    title="There's a problem"
    message="Check the form. You must:"
    alertItems={[
      {
        id: 1,
        href: "document-rejection",
        message: "make sure a description is added for rejecting this document",
      },
    ]}
  />
)

// Use this component rather than the CancelRequestModal, as the CancelRequestModal functionality
// will be refactored into this component as part of this ticket: 17161 - https://dev.azure.com/secure-the-file/Application/_workitems/edit/17616
export const RejectDocumentModal: FC<RejectDocumentModalProps> = ({
  setShowRejectModal,
  label,
  handleReject,
  headerLabel = "Reject document",
  buttonLabel = "Reject",
  rejectError = DefaultRejectError,
}) => {
  const [rejectInput, setRejectInput] = useState("")
  const [hasRejectError, setHasRejectError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Modal name="Reject Document" onClose={() => setShowRejectModal(false)}>
      <ModalHeader>{headerLabel}</ModalHeader>
      <ModalContent>
        {hasRejectError && rejectError}
        <div className="reject-document-modal-content">{label}</div>
        <TextArea
          id="document-rejection"
          name="Document Rejection"
          label="Why are you rejecting this document?"
          value={rejectInput}
          onChange={(value) => setRejectInput(value)}
          isRequired={true}
          hasError={hasRejectError}
          errorLabel="Required"
        />
      </ModalContent>
      <ModalFooter>
        <Button
          variant={BUTTON_VARIANT.DANGER_PRIMARY}
          ariaLabel={buttonLabel}
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true)
            await handleReject({ rejectInput, setHasRejectError })
            setIsSubmitting(false)
          }}
        >
          {buttonLabel}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
