import dayjs from "dayjs"
import {
  FilterType,
  TFilterOption,
} from "../../component/modules/Filter/Filter"
import { DATE_OPTIONS_NAMES } from "../../component/modules/Filter/helpers"
import { PAGE_PATH } from "../../contexts/application/constants"
import { LocalStorageKeys } from "../../contexts/auth/localStorageKeys"

export const MaxFileNameLength = 100

export const Max2FACodeLength = 6

export const CoopCompanyName = "Co-op Legal Services"

/**
 * Determines if the current route is the document library page.
 * First checks if there is a stored IsPrimaryUser value in localStorage - if so, uses that.
 * Otherwise, checks if the current URL path matches the document library page path.
 * This is used to determine which SSO flow to use for authentication.
 * The reason this checks localStorage first is because of a complication from FNZ
 * Advisers and Admins always land on the / route and are then redirected to the /document-library route.
 * If we do not set isPrimaryUser in localStorage, then when the Admin or Adviser refreshes the page on /document-library
 * They are treated like a primary user, which is not what we want. So we set isPrimaryUser to false in localStorage
 * which maintains the correct SSO flow when they refresh the page.
 */
export const isCurrentRouteDocumentLibraryPage =
  localStorage.getItem(LocalStorageKeys.IsPrimaryUser) !== null
    ? localStorage.getItem(LocalStorageKeys.IsPrimaryUser) === "true"
    : window.location.pathname === PAGE_PATH.AccountDocumentLibraryPage

export const DocumentRequestNameMaxLength = 50

export const dateOptionsInitial = [
  {
    name: "1 month",
    value: dayjs().subtract(1, "month").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "3 months",
    value: dayjs().subtract(3, "month").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "6 months",
    value: dayjs().subtract(6, "month").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "1 year",
    value: dayjs().subtract(1, "year").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "5 years",
    value: dayjs().subtract(5, "year").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: "10 years",
    value: dayjs().subtract(10, "year").unix().toString(),
    checked: false,
    type: FilterType.DATE,
  },
  {
    name: DATE_OPTIONS_NAMES.CUSTOMDATERANGE,
    checked: false,
    value: dayjs().unix().toString(),
    type: FilterType.DATE,
  },
] as TFilterOption[]
