import { FC, useEffect } from "react"

import { IFile } from "../../../../api/api-client/api-types"
import { DocusignSignedStatus } from "../../../../api/lib/docuSign/models/DocusignSignedStatus"
import { CaseDocument } from "../../../../api/lib/workflow/models/GetCaseDocumentResponse"
import {
  changeFileInFolder,
  changeFileInSharedWithMeFolder,
} from "../../../../contexts/application/action"
import { UserModuleType } from "../../../../contexts/application/constants"
import { useApplicationContext } from "../../../../contexts/application/context"
import { useUserContext } from "../../../../contexts/users"
import { getNodesToShareWith } from "../../../../utils/shares/helpers"
import { DocumentOrFileStatusLozenge } from "../../../atoms/DocumentOrFileStatusLozenge/DocumentOrFileStatusLozenge"
import {
  CaseDocumentStatusLozenge,
  DocumentStatus,
} from "../../../atoms/Lozenge/CaseDocumentStatusLozenge/CaseDocumentStatusLozenge"
import { GetFileType } from "../../../helpers/ResolveFunctions"

import "./FileDetails.css"

export const FileDetails: FC<{ file: IFile; caseDocument?: CaseDocument }> = ({
  file,
  caseDocument,
}) => {
  const {
    applicationState: { contactsShares, selectedTheme },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  useEffect(() => {
    dispatch(
      file.isOwner
        ? changeFileInFolder({
            file: { ...file, beenViewed: true },
            folderId: file.parentId ?? "",
          })
        : changeFileInSharedWithMeFolder({
            file: { ...file, beenViewed: true },
            folderId: file.parentId ?? "",
          })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const nodesToSharedWith = getNodesToShareWith({
    nodeShares: contactsShares,
    file,
    currentUser,
    selectedTheme,
  })

  const hasVaultUploadForSigning = currentUser?.modules?.includes(
    UserModuleType.VAULT_DOCUMENT_UPLOAD_FOR_SIGNING
  )
  const isSharedFile = file.ownerId !== currentUser?.userId

  const shouldShowSharedWith =
    !isSharedFile && currentUser?.modules?.includes(UserModuleType.SHARING)

  return (
    <div className="section-divider">
      {shouldShowSharedWith && !isSharedFile && (
        <>
          <p
            data-testid="file-details-shared-label"
            className="file-details-label"
          >
            Shared with
          </p>
          <p className="file-details-text" data-testid="file-details-text">
            {nodesToSharedWith && nodesToSharedWith.length > 0
              ? nodesToSharedWith.map((n) => n.name).join(", ")
              : "None"}
          </p>
        </>
      )}
      {/* Bottom row, date, format */}
      <div className="file-details-bottom-row">
        <div className="file-details-bottom-column">
          <p className="file-details-label">Format</p>
          <p className="file-details-text" data-testid="file-details-text">
            {selectedTheme !== "CO_OP"
              ? GetFileType(file?.extension)
              : file?.extension?.toUpperCase()}
          </p>
          {caseDocument?.requestName && (
            <>
              <p className="file-details-label">Document category</p>
              <p className="file-details-text" data-testid="file-details-text">
                {caseDocument?.requestName}
              </p>
            </>
          )}
          {caseDocument?.displayStatus &&
            caseDocument?.displayStatus !== DocumentStatus.None && (
              <>
                <p className="file-details-label">Status</p>
                <CaseDocumentStatusLozenge
                  status={caseDocument?.displayStatus}
                />
              </>
            )}
          {file?.signedStatus === DocusignSignedStatus.REJECTED &&
            file?.docusignActionComment && (
              <>
                <p className="file-details-label">Reason for rejection</p>
                <p className="file-details-text">
                  {file?.docusignActionComment}
                </p>
              </>
            )}
          {hasVaultUploadForSigning && file?.isDocusignFile && (
            <>
              <p className="file-details-label">Status</p>
              <p>
                <DocumentOrFileStatusLozenge label={file?.signedStatus ?? ""} />
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
