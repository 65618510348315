import {
  DistributionFileNodeDto,
  FlatFileNodeDto,
} from "legado-generated-api-client"
import {
  ContactDto,
  FileNodeDto,
  NodeShareDto,
} from "legado-generated-api-client/models"
import { isNullEmptyOrUndefined } from "../../utils/assertions/typeAssertions"
import { IContact, IFile, IFolder, INodeShare, IUser } from "./api-types"

const isIFile = (file: any) => {
  if (file.created as IFile) {
    return file as IFile
  }
}

export const convertFilesDtoToIFiles = (files: FileNodeDto[]) => {
  const iFiles = [] as IFile[]
  files.forEach((f) => {
    const iF = isIFile(f)
    if (iF) {
      iFiles.push(iF)
    }
  })
  return iFiles
}

const sharingPermissionNodeApiToApp = (
  sharingPermissionsNode: NodeShareDto
) => {
  if ((sharingPermissionsNode as INodeShare).nodeId) {
    return sharingPermissionsNode as INodeShare
  }
}
export const convertApiToAppNodeShares = (sharingPermissionsDtos: any[]) => {
  const newNodeShares = [] as INodeShare[]
  sharingPermissionsDtos.forEach((sharingPermissionsDto) => {
    const sharingPermissions = sharingPermissionNodeApiToApp(
      sharingPermissionsDto
    )
    if (sharingPermissions) {
      newNodeShares.push(sharingPermissions)
    }
  })
  return newNodeShares
}

const sharingPermissionNodeAppToApi = (sharingPermissionsNode: INodeShare) => {
  if ((sharingPermissionsNode as NodeShareDto).nodeId) {
    return {
      nodeId: sharingPermissionsNode.nodeId,
      contactId: sharingPermissionsNode.contactId,
      name: sharingPermissionsNode.name,
      contactType: sharingPermissionsNode.contactType,
      canRead: sharingPermissionsNode.canRead,
      canEdit: sharingPermissionsNode.canEdit,
      sharedAfterDeath: sharingPermissionsNode.sharedAfterDeath,
      dateShared: sharingPermissionsNode.dateShared,
    } as NodeShareDto
  }
}

export const convertAppToApiNodeShares = (
  sharingPermissionsNodes: INodeShare[]
) => {
  const sharingPermissionsNodesDto = [] as NodeShareDto[]
  sharingPermissionsNodes.forEach((sharingPermissionsNode) => {
    const sharingPermissionsNodeDto = sharingPermissionNodeAppToApi(
      sharingPermissionsNode
    )
    if (sharingPermissionsNodeDto) {
      sharingPermissionsNodesDto.push(sharingPermissionsNodeDto)
    }
  })
  return sharingPermissionsNodesDto
}

export const convertContactDtoToIContact = (contact: ContactDto) => {
  return {
    contactId: contact.contactId,
    created:
      typeof contact.created === "string"
        ? contact.created
        : contact.created?.toISOString(),
    email: contact.email,
    firstName: contact.firstName,
    isPending: contact.isPending,
    name: contact.name,
    relationship: contact.relationship,
    contactType: contact.contactType,
    surname: contact.surname,
  } as IContact
}

export const convertApiToAppContact = (contacts: any[]) => {
  const newContacts = [] as IContact[]
  if (contacts && contacts.length > 0) {
    contacts.forEach((contact) => {
      if ((contact as IContact).contactId) {
        newContacts.push(contact)
      }
    })
  }
  return newContacts
}

export const convertApiToAppUser = (user: any) => {
  if ((user as IUser).userId) {
    return user as IUser
  }
}

export const convertApiToAppUsers = (users: any[]) => {
  if (users.length === 0) return []
  const newUsers = [] as IUser[]
  users.forEach((user) => {
    if ((user as IUser).userId) {
      newUsers.push(user)
    }
  })
  return newUsers
}

const getHeadAccountName = (flatFile: FlatFileNodeDto) => {
  return isNullEmptyOrUndefined(flatFile.folderName)
    ? flatFile.grandparentFolderName
    : flatFile.folderName
}

export const convertFlatFileNodeDtoToIFile = (flatFile: FlatFileNodeDto) => {
  const iFile = flatFile as IFile
  iFile.productName = getHeadAccountName(flatFile)
  iFile.name = flatFile.name ?? ""
  return iFile as unknown as IFile
}

export const convertDistributionNodeDtoToIFile = (
  distributionFile?: DistributionFileNodeDto
) => {
  if (!distributionFile) return
  const iFile = distributionFile as IFile
  iFile.name = distributionFile.name ?? ""
  return iFile
}

export const isIFolder = (folder: any) => {
  if ((folder as IFolder).name) {
    return folder as IFolder
  }
}

const deepConvertFolder = (folder: any) => {
  if (folder.childFolders) {
    folder.childFolders = convertDtoToIFolders(folder.childFolders)
    folder.childFolders.forEach((f: any) => {
      deepConvertFolder(f)
    })
  }
}

export const convertDtoToIFolder = (folder: any) => {
  const iFolder = isIFolder(folder)
  if (iFolder) {
    deepConvertFolder(iFolder)
    return iFolder
  }
}

export const convertDtoToIFolders = (folders: any[]) => {
  const newFolders = [] as IFolder[]
  if (Array.isArray(folders)) {
    folders.forEach((folder) => {
      const iFolder = convertDtoToIFolder(folder)
      if (iFolder) {
        newFolders.push(iFolder)
      }
    })
  }
  return newFolders
}

export const fromContactResponseToContactList = (contactResponses: any[]) => {
  const contacts = [] as IContact[]
  if (contactResponses)
    contactResponses.forEach((contactDto) => {
      const contact = convertContactDtoToIContact(contactDto)
      if (contact) {
        contacts.push(contact)
      }
    })
  return contacts
}

export const fromUserArrayResponseToUserList = (userResponses: any[]) => {
  const users = [] as IUser[]
  userResponses.forEach((userDb) => {
    const user = convertApiToAppUser(userDb)
    if (user) {
      users.push(user)
    }
  })
  return users
}

export const convertSelectedThemeToDisplayName = (selectedTheme?: string) => {
  const lowerTheme = (selectedTheme ?? "").toLowerCase()
  const displayThemeName =
    lowerTheme.charAt(0)?.toUpperCase() + lowerTheme.slice(1)?.toLowerCase()
  return displayThemeName
}
