import {
  getFoldersFromLocalData,
  getUsersSharingWithMeFromLocalData,
} from "react-app-config"
import { IFile, IFolder } from "../../../../api/api-client/api-types"
import { getFilteredFoldersFromLocalData } from "../../../../api/api-client/folder/getFilteredFoldersFromLocalData"
import { CaseDocument } from "../../../../api/lib/workflow/models/GetCaseDocumentResponse"
import { UserModuleType } from "../../../../contexts/application/constants"
import { useCustomAppState } from "../../../../hooks/useCustomAppState"
import { useStandardUser } from "../../../../hooks/useStandardUser"
import { sharedFoldersToIFolders } from "../../../../utils/folder/sharedFoldersToIFolder"
import { FileDetails } from "../FileDetails/FileDetails"
import { FileDetailsActions } from "./FileDetailsActions"

// must be adviser because of the modules in the useStandardUser.ts
const workflowCaseTestUser = "Quest.Vizier@quilter.com"

const caseDocument = {
  id: 2,
  fileId: "91a9efec-59c8-4555-9206-ffc05083062b",
  file: {
    fileName: "delete-button-test.pdf",
    extension: "PDF",
    mimeType: "application/pdf",
    sizeInBytes: 1671375,
    folderName: "Version 1 - LukaszActionBanner",
    associatedProduct: null,
    isEditable: true,
    isDeletable: true,
    isVisibilityEditable: false,
    hasThumbnail: true,
    hasPreview: false,
    canBeReviewed: false,
    hasBeenReviewed: false,
    stillNeedsMalwareScan: false,
    isExternalDocument: false,
    visibility: "ClientAndAdviser",
    deleted: false,
    isDistributedFile: false,
    customerHasRead: false,
    isImportantDocument: false,
    base64StringThumbnail: undefined, // Update to undefined
    versionNumber: 0,
    hasCommenting: false,
    approved: false,
    lastCommentedByUserId: undefined, // Update to undefined
    id: "91a9efec-59c8-4555-9206-ffc05083062b",
    parentId: "3884c38e-fcb0-43a5-bd08-79b63a54d43a",
    ownerId: "00000000-0000-0000-0000-000000000000",
    createdById: "bf30447b-fcb7-43f7-bfe6-fa94d5ba5e2c",
    isOwner: false,
    name: "delete-button-test",
    dateShared: null,
    created: "2024-05-23T09:27:52Z",
    updated: null,
    createdBy: "Lukasz Banner",
  },
  createdById: "bf30447b-fcb7-43f7-bfe6-fa94d5ba5e2c",
  createdByExternalId: null,
  name: "delete-button-test",
  workflowId: "cb6ebeb0-94bd-4eae-9500-0b9f36e6e254",
  workflowVersionId: 1,
  caseId: 41,
  caseExternalId: "LukaszActionBanner",
  stageId: 1,
  requestId: 2,
  requestName: "John Smith's Will",
  created: "2024-05-23T09:27:52Z",
  updated: "2024-05-23T09:27:58Z",
  fileName: "delete-button-test.pdf",
  extension: "pdf",
  mimeType: "application/pdf",
  sizeInBytes: 1671375,
  currentStatusId: 3002,
  currentStatus: "Uploaded By User",
  isComplete: false,
  externalId: "6e9ba920-f982-4603-8fc2-5ae9c4b96223",
  isEditable: true,
  isDeletable: true,
  hasCommenting: false,
  hasPreview: false,
  hasThumbnail: true,
  actionType: "upload",
  displayStatus: "Uploaded",
  executors: "All: Personal Rep",
  remindersEnabled: false,
  requestExternalId: "test",
  lastUpdatedBy: "test",
}
const caseDocumentFile = caseDocument.file
const caseDocumentUserIsOwner = {
  ...caseDocument,
  createdById: "d50d8c09-cc98-431d-a34d-26efc90e5r3f",
}

const caseDocumentSignature = {
  id: 2,
  fileId: "91a9efec-59c8-4555-9206-ffc05083062b",
  file: {
    fileName: "reject-button-test.pdf",
    extension: "PDF",
    mimeType: "application/pdf",
    sizeInBytes: 1671375,
    folderName: "Version 1 - LukaszActionBanner",
    associatedProduct: null,
    isEditable: true,
    isDeletable: true,
    isVisibilityEditable: false,
    hasThumbnail: true,
    hasPreview: false,
    canBeReviewed: false,
    hasBeenReviewed: false,
    stillNeedsMalwareScan: false,
    isExternalDocument: false,
    visibility: "ClientAndAdviser",
    deleted: false,
    isDistributedFile: false,
    customerHasRead: false,
    isImportantDocument: false,
    base64StringThumbnail: undefined, // Update to undefined
    versionNumber: 0,
    hasCommenting: false,
    approved: false,
    lastCommentedByUserId: undefined, // Update to undefined
    id: "91a9efec-59c8-4555-9206-ffc05083062b",
    parentId: "3884c38e-fcb0-43a5-bd08-79b63a54d43a",
    ownerId: "00000000-0000-0000-0000-000000000000",
    createdById: "bf30447b-fcb7-43f7-bfe6-fa94d5ba5e2c",
    isOwner: false,
    name: "reject-button-showing",
    dateShared: null,
    created: "2024-05-23T09:27:52Z",
    updated: null,
    createdBy: "Lukasz Banner",
  },
  createdById: "bf30447b-fcb7-43f7-bfe6-fa94d5ba5e2c",
  createdByExternalId: null,
  name: "reject-button-showing",
  workflowId: "cb6ebeb0-94bd-4eae-9500-0b9f36e6e254",
  workflowVersionId: 1,
  caseId: 41,
  caseExternalId: "DocNeedsSigned",
  stageId: 1,
  requestId: 2,
  requestName: "John Smith's Will",
  created: "2024-05-23T09:27:52Z",
  updated: "2024-05-23T09:27:58Z",
  fileName: "delete-button-test.pdf",
  extension: "pdf",
  mimeType: "application/pdf",
  sizeInBytes: 1671375,
  currentStatusId: 3005,
  currentStatus: "Required for action",
  isComplete: false,
  externalId: "6e9ba920-f982-4603-8fc2-5ae9c4b96223",
  isEditable: true,
  isDeletable: true,
  hasCommenting: false,
  hasPreview: false,
  hasThumbnail: true,
  actionType: "review-sign",
  displayStatus: "Signature",
  executors: "All: Personal Rep",
  remindersEnabled: false,
  requestExternalId: "test",
  lastUpdatedBy: "test",
}
const caseDocumentSignatureFile = caseDocumentSignature.file

const caseDocumentSigned = {
  id: 2,
  fileId: "91a9efec-59c8-4555-9206-ffc05083062b",
  file: {
    fileName: "reject-button-hidden.pdf",
    extension: "PDF",
    mimeType: "application/pdf",
    sizeInBytes: 1671375,
    folderName: "Version 1 - LukaszActionBanner",
    associatedProduct: null,
    isEditable: true,
    isDeletable: true,
    isVisibilityEditable: false,
    hasThumbnail: true,
    hasPreview: false,
    canBeReviewed: false,
    hasBeenReviewed: false,
    stillNeedsMalwareScan: false,
    isExternalDocument: false,
    visibility: "ClientAndAdviser",
    deleted: false,
    isDistributedFile: false,
    customerHasRead: false,
    isImportantDocument: false,
    base64StringThumbnail: undefined, // Update to undefined
    versionNumber: 0,
    hasCommenting: false,
    approved: false,
    lastCommentedByUserId: undefined, // Update to undefined
    id: "91a9efec-59c8-4555-9206-ffc05083062b",
    parentId: "3884c38e-fcb0-43a5-bd08-79b63a54d43a",
    ownerId: "00000000-0000-0000-0000-000000000000",
    createdById: "bf30447b-fcb7-43f7-bfe6-fa94d5ba5e2c",
    isOwner: false,
    name: "reject-button-hidden",
    dateShared: null,
    created: "2024-05-23T09:27:52Z",
    updated: null,
    createdBy: "Lukasz Banner",
  },
  createdById: "bf30447b-fcb7-43f7-bfe6-fa94d5ba5e2c",
  createdByExternalId: null,
  name: "reject-button-hidden",
  workflowId: "cb6ebeb0-94bd-4eae-9500-0b9f36e6e254",
  workflowVersionId: 1,
  caseId: 41,
  caseExternalId: "DocIsSigned",
  stageId: 1,
  requestId: 2,
  requestName: "John Smith's Will",
  created: "2024-05-23T09:27:52Z",
  updated: "2024-05-23T09:27:58Z",
  fileName: "delete-button-test.pdf",
  extension: "pdf",
  mimeType: "application/pdf",
  sizeInBytes: 1671375,
  currentStatusId: 3008,
  currentStatus: "Actioned",
  isComplete: false,
  externalId: "6e9ba920-f982-4603-8fc2-5ae9c4b96223",
  isEditable: true,
  isDeletable: true,
  hasCommenting: false,
  hasPreview: false,
  hasThumbnail: true,
  actionType: "review-sign",
  displayStatus: "Signature",
  executors: "All: Personal Rep",
  remindersEnabled: false,
  requestExternalId: "test",
  lastUpdatedBy: "test",
}
const caseDocumentSignedFile = caseDocumentSigned.file

const karimsEmail = "karim.mehdi@legadovault.onmicrosoft.com"
const karimsFolders = getFoldersFromLocalData(karimsEmail) as IFolder[]
const savingsAndInvestments = karimsFolders.find(
  (section) => section.name === "Savings and Investments"
)!
//imported file
const interestStatementFile = savingsAndInvestments.childFolders
  ?.find(
    (folder) => folder.name === "Barclays - ICISA - Instant Cash ISA - 11111115"
  )
  ?.contents?.find((file) => file.name === "21-22 Interest Statement")!

//file uploaded by karim
const secretKeyFile = savingsAndInvestments.childFolders
  ?.find((folder) => folder.name === "Bitcoin")
  ?.contents?.find((file) => file.name === "Secret Key")!

const karimsSharees = getUsersSharingWithMeFromLocalData(karimsEmail)
const josie = karimsSharees.find((sharee) => sharee.firstName === "Josie")!
const josiesFoldersSharedWithKarim = sharedFoldersToIFolders(
  getFilteredFoldersFromLocalData(karimsEmail, josie.userId!)
)
const HomeAndProperty = josiesFoldersSharedWithKarim.find(
  (folder) => folder.folderType === "HomeAndProperty"
)!
const josiesPropertyFolder = HomeAndProperty.childFolders?.find(
  (folder) => folder.folderType === "Property"
)!

//shared file with edit permissins

const dreamHouseBudgetFile = josiesPropertyFolder?.contents?.find((file) =>
  file.fileName?.includes("Dream house budget.xlsx")
)!
//shared file with read only permissionss
const architectPitchFile = josiesPropertyFolder?.contents?.find(
  (file) => file.name === "Architect pitch"
)!

interface TestFileDetailsProps {
  file: IFile
  caseDocument?: CaseDocument
  excludedModules?: UserModuleType[]
}

const FileDetailsKarim = ({
  file,
  excludedModules = [],
}: TestFileDetailsProps) => {
  useStandardUser({ email: karimsEmail, excludedModules })
  useCustomAppState({ selectedFileId: file.id })
  return <FileDetails file={file} />
}

const FileDetailsActionsKarim = ({
  file,
  excludedModules = [],
}: TestFileDetailsProps) => {
  useStandardUser({ email: karimsEmail, excludedModules })
  useCustomAppState({ selectedFileId: file.id })
  return (
    <FileDetailsActions
      file={file}
      getDocusignUrl={() => Promise.resolve("url")}
    />
  )
}

const FileDetailsActionsForWorkflows = ({
  file,
  caseDocument,
  excludedModules = [],
}: TestFileDetailsProps) => {
  useStandardUser({ email: workflowCaseTestUser, excludedModules })
  useCustomAppState({ selectedFileId: file.id })
  return (
    <FileDetailsActions
      file={file}
      caseDocument={caseDocument}
      getDocusignUrl={() => Promise.resolve("url")}
    />
  )
}

export const testData = {
  files: {
    caseDocumentFile,
    caseDocumentSignatureFile,
    caseDocumentSignedFile,
    interestStatementFile,
    secretKeyFile,
    dreamHouseBudgetFile,
    architectPitchFile,
  },
  caseDetails: {
    caseDocument,
    caseDocumentSignature,
    caseDocumentSigned,
    caseDocumentUserIsOwner,
  },

  components: {
    FileDetailsKarim,
    FileDetailsActionsKarim,
    FileDetailsActionsForWorkflows,
  },
}
