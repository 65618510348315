import { Lozenge } from "../Lozenge/Lozenge"
import "./DocumentOrFileStatusLozenge.css"

export enum DocumentOrFileStatus {
  None = "",
  Pending = "Pending",
  Signed = "Signed",
  Approved = "Approved",
  Rejected = "Rejected",
  Review = "Review",
  Commented = "Commented",
  Viewed = "Viewed",
  NotViewed = "Not viewed",
}

export const DocumentOrFileStatusLozenge = ({ label }: { label: string }) => {
  const status = label as DocumentOrFileStatus
  switch (status) {
    case DocumentOrFileStatus.Signed:
    case DocumentOrFileStatus.Approved:
    case DocumentOrFileStatus.Viewed:
      return <Lozenge className="green-lozenge" label={label} />

    case DocumentOrFileStatus.Pending:
    case DocumentOrFileStatus.NotViewed:
      return <Lozenge className="grey-lozenge" label={label} />

    case DocumentOrFileStatus.Rejected:
      return <Lozenge className="red-lozenge" label={label} />

    case DocumentOrFileStatus.Review:
      return <Lozenge className="yellow-lozenge" label={label} />

    case DocumentOrFileStatus.Commented:
      return <Lozenge className="blue-lozenge" label={label} />

    default:
      return null
  }
}
